import React, { useMemo, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { NewItemModalField, NewItemKey } from "../../types/post-new";
import NewItemFormField from "./new-item-form-field";
import { DataError, SavingStatus } from "../../types/form-types";
import {
    allFieldsAreFilled,
    formHasErrors,
    getIdFromDisplayData,
} from "../../modules/new-item-helpers/new-item-helpers";
import SaveButton from "../button/save-button";
import ActionButton from "../button/action-button";
import { history } from "../../pages/root";
import { DetailPagePath } from "../../modules/api";
import { strings } from "../../modules/strings/strings";
import { AppPage } from "../../ducks/ui";
const { errorStrings } = strings;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "absolute",
            width: 600,
            backgroundColor: theme.palette.background.paper,
            border: "1px solid #CCC",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    })
);

type NewItemFormProps = {
    displayData: any;
    heading: string;
    subheading: string;
    formFields: NewItemModalField[];
    errors: Map<NewItemKey, DataError>;
    savingStatus: SavingStatus;
    newItemPath: DetailPagePath;
    backToPage: AppPage;
    handleClose: () => void;
    onSubmit: (postData: any) => Promise<SavingStatus>;
    setPostValue: (key: string, value: any) => void;
};

function NewItemForm({
    handleClose,
    displayData,
    onSubmit,
    formFields,
    heading,
    subheading,
    setPostValue,
    errors,
    savingStatus,
    newItemPath,
    backToPage,
}: NewItemFormProps) {
    const classes = useStyles();
    const theme = useTheme();
    const [errorText, setErrorText] = useState<string>("");

    const canSave: boolean = useMemo(() => {
        const can: boolean = allFieldsAreFilled(formFields, displayData) && !formHasErrors(errors);
        if (can) setErrorText("");
        return can;
    }, [formFields, displayData, errors, setErrorText]);

    return (
        <div className={classes.paper} style={{ borderRadius: 4 }}>
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                style={{ marginBottom: 20 }}
                noWrap
            >
                {heading}
            </Typography>
            <Typography
                component="p"
                color="inherit"
                style={{ marginBottom: 20, whiteSpace: "pre-line" }}
            >
                {subheading}
            </Typography>
            <div>
                {formFields.map(field => {
                    return (
                        <NewItemFormField
                            key={field.key}
                            displayData={displayData}
                            field={field}
                            setPostValue={setPostValue}
                            validationCheck={errors.get(field.key)}
                        />
                    );
                })}
            </div>

            <div
                className="button-area"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 20,
                }}
            >
                <div>
                    <span style={{ color: theme.palette.error.main }}>{errorText}</span>
                </div>
                <div>
                    <ActionButton label="Cancel" onClick={handleClose} />
                    <SaveButton
                        label="Add"
                        onSave={() => {
                            onSubmit(displayData).then(status => {
                                if (status.savingError.error) {
                                    setErrorText(status.savingError.message || "Saving error");
                                } else {
                                    // Navigate straight to the new item that's been created
                                    const id = getIdFromDisplayData(
                                        newItemPath,
                                        displayData,
                                        status
                                    );

                                    if (id) {
                                        history.push({
                                            pathname: `/dashboard/${newItemPath}/${id}`,
                                            state: backToPage,
                                        });
                                    }
                                    handleClose();
                                }
                            });
                        }}
                        onBlockedSave={() => {
                            setErrorText(errorStrings.validation.fillAllFields);
                        }}
                        savingBlocked={!canSave}
                        savingInProgress={savingStatus.savingInProgress}
                    />
                </div>
            </div>
        </div>
    );
}

export default NewItemForm;
